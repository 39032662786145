import React from 'react';
import PropTypes from 'prop-types';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth } from 'react-oidc-context';

const AuthorizedApolloProvider = ({ children }) => {
  const { user } = useAuth();
  const currentHostname = window.location.hostname;
  const httpLink = createHttpLink({
    uri:
      currentHostname === 'localhost'
        ? 'https://api.one.cubedev.toyota.com/graphql'
        : `https://api.${currentHostname}/graphql`,
  });

  const authLink = setContext(() => {
    return {
      headers: {
        Authorization: user.access_token,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthorizedApolloProvider;
