import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ToastNotification from '../components/ToastNotification/ToastNotification';

export const Notification = createContext({});

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({});
  const show = notification && !!Object.values(notification)?.length;

  useEffect(() => {
    const timer = show && setTimeout(() => setNotification({}), notification?.timeout || 10000);
    return () => clearTimeout(timer);
  }, [notification]);

  const dispatchNotification = (notificationProps) => setNotification(notificationProps);

  const mapStyles = () => {
    switch (notification?.position) {
      case 'bottom':
        return {
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          pointerEvents: 'none',
        };
      case 'top':
        return {
          top: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          pointerEvents: 'none',
        };
      case 'bottom-right':
        return { bottom: 0, right: 0 };
      case 'bottom-left':
        return { bottom: 0, left: 0 };
      case 'top-left':
        return { top: 0, left: 0 };
      case 'top-right':
        return { top: 0, right: 0 };
      default:
        return {};
    }
  };

  return (
    <Notification.Provider value={{ dispatchNotification }}>
      {children}
      {show && (
        <Box m="2rem" zIndex="10000" position="fixed" sx={mapStyles()}>
          <Box sx={{ pointerEvents: 'all' }}>
            <ToastNotification open onClose={() => setNotification({})} {...notification} />
          </Box>
        </Box>
      )}
    </Notification.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
