import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import envFeatures from './features.json';
import { getEnv } from '../utils/environment';

export const FeatureFlags = createContext({});

const environment = getEnv();
export const features = envFeatures[environment];

const FeaturesProvider = ({ children }) => (
  <FeatureFlags.Provider value={{ environment, features }}>{children}</FeatureFlags.Provider>
);

FeaturesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeaturesProvider;
