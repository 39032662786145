import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { FeatureFlags } from 'contexts/Features.context';
import { Roles } from 'contexts/Roles.context';

const Home = lazy(() => import('./pages/Home/Home'));
const RedirectToArrival = lazy(() => import('./pages/Arrival/RedirectToArrival'));
const RedirectToPipeline = lazy(() => import('./pages/Pipeline/RedirectToPipeline'));
const ErrorNotFound = lazy(() => import('./pages/ErrorNotFound/ErrorNotFound'));

const Router = () => {
  const auth = useAuth();
  const { features } = useContext(FeatureFlags);
  const { permissions } = useContext(Roles);

  switch (auth.activeNavigator) {
    case 'signinSilent':
    case 'signinRedirect':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      sessionStorage.setItem('cube-pathname', window.location.pathname);
    }
    auth.signinRedirect();
    return null;
  }

  const permissionExists = (name) => {
    if (!features?.userRoles) return true;
    return !!permissions?.filter((item) => item.applicationName === name)?.length;
  };
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner fullScreen />}>
        <Routes>
          <Route index path="/" element={<Home />} />
          {permissionExists('ETACT') && (
            <>
              <Route index exact path="/arrival" element={<RedirectToArrival />} />
              <Route path="/arrival/historical" element={<RedirectToArrival />} />
            </>
          )}

          {permissionExists('E2E') && features.pipelineEnabled && (
            <>
              <Route index path="/pipeline" element={<RedirectToPipeline />} />
              <Route path="/pipeline/reports/active-holds" element={<RedirectToPipeline />} />
              <Route path="/pipeline/reports/deletion-report" element={<RedirectToPipeline />} />
              <Route
                path="/pipeline/reports/production-and-vessel-arrival-status"
                element={<RedirectToPipeline />}
              />
            </>
          )}
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
