import { createTheme } from '@mui/material/styles';
import typography from './typography';

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
    },
  },
  typography: {
    fontFamily: 'Toyota Type',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    hero: {
      fontSize: '3rem',
      fontWeight: 600,
    },
  },
  palette: {
    background: {
      default: '#F8F8F9',
      light: '#f1f2f4',
      dark: '#dbdcde',
      paper: '#FFFFFF',
    },
    divider: '#D8D8D8',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#191A1C',
      light: '#767676',
      dark: '#303132',
    },
    info: {
      main: '#2469FF',
    },
    success: {
      main: '#22A63E',
    },
    warning: {
      main: '#E66C02',
    },
    error: {
      main: '#B00716',
      e50: '#c40818',
      e80: '#ffa4a4',
    },
    neutral: {
      n92: '#e6e6e6',
    },
    grey: {
      main: '#767676',
      light: '#58595b',
      dark: '#191A1C',
    },
    action: {
      disabledBackground: '#dbdcde',
      disabled: '#909295',
    },
    data: {
      light: '#5900d9',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: typography,
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: '2rem',
          paddingRight: '2rem',
          [theme.breakpoints.down('tablet')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        }),
        disableGutters: {
          padding: 0,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: '0.25rem',
          },
        },
      },
      defaultProps: {
        centerRipple: false,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          padding: '0.25rem 0.5rem',
          height: '1.5rem',
        },
        label: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '6.5rem',
          zIndex: 1100,
        },
      },
    },
  },
});

export default theme;
