import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from '../queries/GetUserInfo';

export const Roles = createContext({});

const RolesProvider = ({ children }) => {
  const { data } = useQuery(GET_USER_INFO);

  return (
    <Roles.Provider value={{ permissions: data?.getUserInfo?.permissions || [] }}>
      {children}
    </Roles.Provider>
  );
};

RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RolesProvider;
